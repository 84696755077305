$footerFontSize: 0.75em;

html,
body,
#root {
    height: 100%;
}

#app {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}
#main {
    flex: 1 0 auto;
    .container {
        // z-index: 2;
    }
}

// FC section header titles
.section-container {
    &.level1 {
        .title {
            font-size: xx-large;
        }
    }

    &.level2 {
        .title {
            font-size: x-large;
        }
    }

    &.level3 {
        .title {
            font-size: x-large;
        }
    }
}

#footer {
    flex-shrink: 0;

    color: white;
    background-size: cover;
    display: grid;
    grid-template-columns: 30% 30% 30%;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #ccc;
    font-size: $footerFontSize;
    line-height: 1;
    height: 40px;
    padding-left: 1em;
    padding-right: 1em;

    .left {
        text-align: left;
    }
    .center {
        text-align: center;
    }
    .right {
        text-align: right;
    }
    a {
        &:link,
        &:visited {
            color: white;
            font-weight: bold;
        }
        &:hover {
            color: rgb(255, 102, 0);
            text-decoration: none;
        }
    }
}

#header {
    transition: all 0.5s ease-out;
    margin-bottom: 2em;
    height: 140px;
    z-index: 2;

    .extras {
        display: flex;
        justify-content: space-between;
    }
    #title {
        transition: all 0.5s ease-out;
    }

    &.compact {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 60px;

        #title {
            font-size: 1.5em;
        }
        .extras {
            display: none;
        }
    }
}

small.text-danger:not(.form-text) {
    font-style: italic;
    font-weight: bold;
    float: right;
}

.form-group.fileWidget ~ .list-group small.text-danger:not(.form-text) {
    float: none;
}

.form-group {
    // border: 1px solid red;
    .form-group {
        // border: 1px solid green;
        .form-group {
            // border: 1px solid blue;
            > .title {
                font-size: large;
                .form-group {
                    // border: 1px solid pink;
                }
            }
        }
    }
}

#formFooter {
    .buttons {
        max-width: 400px;
        display: flex;
        justify-content: space-between;

        button {
            min-width: 6em;
            margin-right: 1em;
        }

        .trigger {
            margin-left: 1em;
            position: relative;
        }

        &.compact {
            justify-content: start;
        }

        .trigger {
            margin-left: 1em;
            position: relative;
        }

        &.compact {
            justify-content: start;
        }
    }

    margin-bottom: 3em;
}

.buttonsTooltip.popover {
    max-width: 100%;
}

ul.messageLinks {
    button {
        border: none;
        background: none;
    }
    margin-top: 1em;
}

$dirtyIconSize: 1.5em;
$fadeFromOpacity: 1;
$fadeToOpacity: 0.5;
$fadeDelay: 3000ms;
$fadeDuration: 5s;

@mixin keyframes($animation_name) {
    @-webkit-keyframes #{$animation_name} {
        @content;
    }

    @-moz-keyframes #{$animation_name} {
        @content;
    }

    @-o-keyframes #{$animation_name} {
        @content;
    }

    @keyframes #{$animation_name} {
        @content;
    }
}

@mixin partialFade($animation) {
    animation-delay: $fadeDelay;
    animation: $animation ease $fadeDuration;
    -webkit-animation: $animation ease $fadeDuration;
    -moz-animation: $animation ease $fadeDuration;
    -o-animation: $animation ease $fadeDuration;
    -ms-animation: $animation ease $fadeDuration;
}

.dirtyIndicator {
    background-color: #888;
    padding: 0.5em 1em;
    color: white;
    font-size: 0.8em;
    font-weight: bold;
    border-radius: 4;
    position: fixed;
    top: 1em;
    left: 50%;
    transform: translateX(-50%);
    text-align: right;
    z-index: 5;
    opacity: $fadeToOpacity;

    &.saved {
        @include partialFade(semiFade);
    }
    &.unsaved {
        @include partialFade(semiFade2);
    }

    .circleIcon {
        margin-left: 0.5em;
        display: inline-block;
        vertical-align: middle;
        border-radius: 50%;
        padding: 0 5px;
        height: $dirtyIconSize;
        width: $dirtyIconSize;
        max-height: $dirtyIconSize;
        max-width: $dirtyIconSize;
        line-height: $dirtyIconSize;

        &.saved {
            background: green;
        }
        &.unsaved {
            background: #ff5c2b;
            color: white;
        }
    }
}

@include keyframes(semiFade) {
    0% {
        opacity: $fadeFromOpacity;
    }
    100% {
        opacity: $fadeToOpacity;
    }
}
@include keyframes(semiFade2) {
    0% {
        opacity: $fadeFromOpacity;
    }
    100% {
        opacity: $fadeToOpacity;
    }
}

$panelWidth: 300px;
#constructor {
    min-width: $panelWidth;
    background: #eee;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-left: 2em;
    padding: 1em 0 1em 1em;
    position: relative;

    .inner {
        width: $panelWidth - (16px * 2);
    }
}

// Overrides for mobile
@media (max-width: 640px) {
    #header {
        #title {
            font-size: 2em;
        }

        .extras {
            font-size: small;
        }
    }

    #footer {
        font-size: x-small;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        height: 22px;
        grid-template-columns: 50% 50%;

        .hideOnMobile {
            display: none;
        }
        .center {
            text-align: right;
        }
    }

    .rjsf {
        h3 {
            font-size: 1.25em;
        }
        .form-group {
            &:first-child {
                margin-bottom: 0;
            }

            .uiLayout .row {
                padding-bottom: 1em;
                margin-bottom: 0.5em;
                border-bottom: 1px dotted #ddd;
            }
        }
        & > .form-group .row:last-child {
            border: none;
            margin-bottom: 0 !important;
        }
    }

    .alert-heading.h4 {
        font-size: 1.25rem;
    }

    .dirtyIndicator {
        top: unset;
        bottom: 2px;
        background: none;
        font-size: x-small;
        .circleIcon {
            height: 1.25em;
            width: 1.25em;
            max-height: 1.25em;
            max-width: 1.25em;
            line-height: 1.25em;
            padding: 0;
            vertical-align: baseline;
        }
        padding: 0;
    }
}

// Get newlines displaying as line breaks in field descriptions
p.description,
.form-group > small.form-text {
    white-space: pre-wrap;
}

// Fix for date widget bottom margin
.form-group > .form-group {
    margin-bottom: 1em !important;
}

.login-form {
    border-radius: 10px;
    border: 1px solid #ddd;
    margin-top: 3em;
    padding: 1em 2em;
    background-color: #eee;
    text-align: center;
    h2 {
        margin-bottom: 1em;
    }
    p {
        font-weight: bold;
        font-style: italic;
    }
    ul {
        padding: 1em 2em;
        li {
            list-style: none;
            margin: 1em 0;
        }
    }
}

// Hide FC form header, because in the app we use it as a page header instead
.section-container.level0 > .header {
    display: none;
}

// Add a bit of space at the bottom of the FC container
.formulationist-container {
    margin-bottom: 2em;
}

// Descriptions didn't look right
.form-field .description {
    font-weight: 400;
    font-style: italic;
}
